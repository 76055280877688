import {withTranslation, WithTranslation} from '@wix/yoshi-flow-editor'
import {MembersTexts, Tabs} from '../../../../commons/enums'
import {isMobile} from '../../../../commons/selectors/environment'
import {closeAllEvents, setTab} from '../../actions/events'
import {getUserText} from '../../selectors/component'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventsTabs as EventsTabsPresentation} from './events-tabs'

export interface EventTabsProps extends EventTabsRuntimeProps, WithTranslation {}

interface EventTabsRuntimeProps {
  setTab: typeof setTab
  tab: Tabs
  pastEventsTabLabel: string
  upcomingEventsTabLabel: string
  mobile: boolean
  rtl: boolean
  closeAllEvents: typeof closeAllEvents
}

const mapRuntime = ({state, actions, isRTL}: MemberPageContext): EventTabsRuntimeProps => ({
  setTab: actions.setTab,
  tab: state.tab,
  pastEventsTabLabel: getUserText(state, MembersTexts.pastEventsTab),
  upcomingEventsTabLabel: getUserText(state, MembersTexts.upcomingEventsTab),
  mobile: isMobile(state),
  rtl: isRTL,
  closeAllEvents: actions.closeAllEvents,
})

export const EventsTabs = connect<{}, EventTabsRuntimeProps>(mapRuntime)(withTranslation()(EventsTabsPresentation))
