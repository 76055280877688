import {StyleParamType, createStylesParams} from '@wix/yoshi-flow-editor/tpa-settings'

export default createStylesParams<{
  backgroundBorderWidth: StyleParamType.Number
}>({
  backgroundBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
})
