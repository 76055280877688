import {withTranslation} from '@wix/yoshi-flow-editor'
import {MembersTexts} from '../../../../commons/enums'
import {isMobile, isSite} from '../../../../commons/selectors/environment'
import {getUserText} from '../../selectors/component'
import {getNoUpcomingEventsUrl} from '../../selectors/navigation'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {NoEventsRuntimeProps} from './interfaces'
import {NoEvents as NoEventsPresentation} from './no-events'

const mapRuntime = ({
  state,
  actions: {seeOtherEvents, navigateToNoUpcomingEventsUrl},
}: MemberPageContext): NoEventsRuntimeProps => ({
  navigate: !isSite(state) ? navigateToNoUpcomingEventsUrl : null,
  url: isSite(state) ? getNoUpcomingEventsUrl(state) : null,
  cta: getUserText(state, MembersTexts.noUpcomingEventsLink),
  mobile: isMobile(state),
  seeOtherEvents,
})

export const NoEvents = connect<{}, NoEventsRuntimeProps>(mapRuntime)(withTranslation()(NoEventsPresentation))
export * from './interfaces'
