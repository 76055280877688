import {getEventOrders} from '../selectors/event'
import {DetailedEvent} from '../types/state'

export const downloadTicketsForEvent = ({event, hasOrder = true, tickets = []}: DownloadTicketsForEventArgs) => {
  if (hasOrder) {
    getEventOrders(event).forEach(downloadTickets)
  } else {
    tickets.forEach(downloadTicket)
  }
}

export const downloadTickets = (order: wix.events.ticketing.Order) => window.open(order.ticketsPdf)

export const downloadTicket = (ticket: wix.events.ticketing.Ticket) => window.open(ticket.ticketPdf)

interface DownloadTicketsForEventArgs {
  event: DetailedEvent
  hasOrder?: boolean
  tickets?: wix.events.ticketing.Ticket[]
}
