import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Description} from '../styled-components/description'
import {PageTitle} from '../styled-components/page-title'
import s from './header.scss'
import {HeaderProps} from '.'

export const Header = ({title, subtitle}: HeaderProps) => (
  <div className={s.root}>
    <div className={s.title}>
      <PageTitle data-hook={DH.APP_HEADER_TITLE}>{title}</PageTitle>
    </div>
    <div className={s.subtitle}>
      <Description data-hook={DH.APP_HEADER_DESCRIPTION}>{subtitle}</Description>
    </div>
  </div>
)
