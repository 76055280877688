import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Divider} from '../styled-components/divider'
import {NoEventsLink} from '../styled-components/no-events-link'
import {NoEventsText} from '../styled-components/no-events-text'
import s from './no-events.scss'
import {NoEventsProps} from './index'

export const NoEvents = ({url, navigate, mobile, cta, t, seeOtherEvents}: NoEventsProps) => {
  const onLinkClick = () => {
    if (!url && navigate) {
      navigate()
    }
    seeOtherEvents()
  }

  return (
    <div data-hook={DH.NO_EVENTS} className={mobile ? s.mobile : null}>
      <div className={s.content}>
        <NoEventsText mobile={String(mobile)}>{t('members-page.no-events.message')}</NoEventsText>
        <div className={s.button}>
          <NoEventsLink mobile={mobile} as="a" href={url} onClick={onLinkClick} data-hook={DH.BUTTON_GO_TO_EVENTS}>
            {cta}
          </NoEventsLink>
        </div>
      </div>
      <Divider />
    </div>
  )
}
