import React from 'react'
import {getEventId} from '@wix/wix-events-commons-statics'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {isTicketedWithNoOrder} from '../../../selectors/event'
import {downloadTicketsForEvent} from '../../../services/tickets'
import {MobileDownloadTicketsButtonProps} from '.'

export const MobileDownloadTicketsButton: React.FC<MobileDownloadTicketsButtonProps> = ({
  event,
  tickets,
  t,
  downloadTicketsAction,
}) => {
  const eventId = getEventId(event.event)
  const ticketedWithNoOrder = isTicketedWithNoOrder(event)
  const eventTickets = tickets[eventId]

  const handleEventLinkClick = () => {
    ticketedWithNoOrder
      ? downloadTicketsForEvent({event, hasOrder: false, tickets: eventTickets})
      : downloadTicketsForEvent({event})
    downloadTicketsAction(eventId)
  }

  return (
    <EventLink primary mobile={true} onClick={handleEventLinkClick} data-hook={DH.MOBILE_BUTTON_DOWNLOAD_TICKETS}>
      {t('events.buttons.download-tickets')}
    </EventLink>
  )
}
